<template language="html">
  <div class="gestao-papeis">
    <div class="row">
      <div class="col-12">
        <v-alert border="right" colored-border type="error" elevation="2">
          <h3>Favor não editar ou remover os seguintes papeis</h3>
          <div class="row">
            <div class="col-4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Desenvolvedor</v-list-item-title>
                  <v-list-item-subtitle>Permissões</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div class="col-4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Comercial</v-list-item-title>
                  <v-list-item-subtitle>Permissões</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div class="col-4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Administrador</v-list-item-title>
                  <v-list-item-subtitle>Permissões</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Recursos Humanos</v-list-item-title>
                  <v-list-item-subtitle>Permissões</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div class="col-4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Gestor</v-list-item-title>
                  <v-list-item-subtitle>Permissões</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div class="col-4">
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-title>Operacional</v-list-item-title>
                  <v-list-item-subtitle>Permissões</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-alert>
      </div>
    </div>
    <crud
      :title="'Gestão de papéis'"
      buttonAddItemText="Novo papel"
      :dialog.sync="dialog"
      :formTitle="formTitle"
      :callBackModalCancelar="fechar"
      :callBackModalSalvar="salvar"
      :callBackModalInsert="resetFields"
      :tamanhoModalCrud="500"
      searchLabelText="Pesquisar na lista"
      :searchTextField.sync="searchTextField"
      :dialogDelete.sync="dialogDelete"
    >
      <template slot="data-table">
        <v-data-table
          :loading="loadingControl.dataTable"
          :headers="computedHeaders"
          :items="listaPapeis"
          :search="searchTextField"
          items-per-page-text="Linhas por página"
          no-results-text="Nenhum registro encontrado"
          no-data-text="Nenhum registro encontrado"
          mobile-breakpoint="0"
          class="elevation-1"
        >
          <template v-slot:[`item.acao`]="{ item }">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="toggleModalLateral(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-eye </v-icon>
                    <span>Visualizar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="editar(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-pencil </v-icon>
                    <span>Editar</span>
                  </v-list-item-title>
                </v-list-item>

                <v-list-item @click="remover(item, true)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2"> mdi-delete </v-icon>
                    <span>Apagar</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:[`item.permissoes`]="{ item }">
            <span v-if="item.permissoes && item.permissoes.length > 0">{{
              item.permissoes.length
            }}</span>
          </template>
          <template v-slot:[`item.created_at`]="{ item }">
            <span>{{ item.created_at | dateTimeFormatBr() }}</span>
          </template>
          <template v-slot:[`item.updated_at`]="{ item }">
            <span>{{ item.updated_at | dateTimeFormatBr() }}</span>
          </template>
        </v-data-table>
      </template>
      <template slot="contentModalCrud">
        <v-container fluid>
          <v-row wrap>
            <v-col cols="12" md="12">
              <v-text-field
                id="name"
                name="name"
                data-vv-validate-on="change|blur|input"
                data-vv-as="nome"
                data-vv-scope="roles"
                :error="errors.collect('roles.nome').length ? true : false"
                :hint="errors.first('roles.nome')"
                v-validate="{ required: true }"
                v-model="objetoEdicao.name"
                persistent-hint
                trim
                type="text"
                label="nome"
                placeholder="nome"
                outlined
                clearable
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-select
                :items="locais"
                label="Guardas"
                id="guard_name"
                name="guard_name"
                data-vv-validate-on="change|blur|input"
                data-vv-as="guarda"
                data-vv-scope="roles"
                :error="
                  errors.collect('roles.guard_name').length ? true : false
                "
                :hint="errors.first('roles.guard_name')"
                v-validate="{ required: true }"
                v-model="objetoEdicao.guard_name"
                persistent-hint
                trim
                type="text"
                outlined
                clearable
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-autocomplete
                v-model="objetoEdicao.permissoes"
                :items="listaPermissoes"
                item-text="name"
                item-value="id"
                label="Permissões"
                multiple
                outlined
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.name }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption"
                    >(+{{
                      objetoEdicao &&
                      objetoEdicao.permissoes &&
                      objetoEdicao.permissoes.length - 1
                    }}
                    outros)</span
                  >
                </template>

                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="toggleSelect('permissoes', $event)"
                  >
                    <v-list-item-action>
                      <v-icon
                        :color="
                          objetoEdicao.permissoes && objetoEdicao.permissoes > 0
                            ? 'indigo darken-4'
                            : ''
                        "
                        >{{ iconSelect("permissoes", "permissao") }}</v-icon
                      >
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{
                          objetoEdicao.permissoes.length !=
                          listaPermissoes.length
                            ? "Selecionar todos"
                            : "Desmarcar todos"
                        }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </template>
      <!-- ------------- MODAL DE DELETE --------------------------------------------- -->
      <template slot="modalDelete/titleModal">Excluir o registro?</template>
      <template slot="modalDelete/contentModal">
        <p>
          Atenção! Você está prestes a realizar uma ação que não pode ser
          desfeita.
        </p>
        <p>Você realmente deseja excluir esse registro?</p>
      </template>
      <template slot="modalDelete/actionsModal">
        <v-btn depressed @click="dialogDelete = false">Fechar</v-btn>
        <v-btn color="error" @click="remover()">Excluir</v-btn>
        <v-spacer class="d-none d-md-block"></v-spacer>
      </template>
    </crud>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate color="#001325" size="75">
        <img :src="image" height="45" alt="" />
      </v-progress-circular>
    </v-overlay>
    <modalLateral
      :show="modalLateral.show"
      :hide="toggleModalLateral"
      :title="modalLateral.title"
      :headers="
        headersModal.filter((header) => {
          return headersModal.value != 'visualizar';
        })
      "
      :item="modalLateral.item"
    >
      <template v-slot:[`item.permissoes`]="{ item }">
        <div v-if="item.permissoes && item.permissoes.length > 0">
          <div v-for="permission in item.permissoes" :key="permission">
            <span class="my-1">{{
              listaPermissoes.find((el) => el.id == permission) == null
                ? "Não tem permissão."
                : listaPermissoes.find((el) => el.id == permission).name
            }}</span>
            <br />
          </div>
        </div>
        <div v-else>
          <span>Não tem permissão.</span>
        </div>
      </template>
      <template v-slot:[`item.created_at`]="{ item }">
        <span
          >{{ item.created_at | dateFormatBr() }}
          {{ item.created_at | timeFormatBr() }}</span
        >
      </template>
      <template v-slot:[`item.updated_at`]="{ item }">
        <span
          >{{ item.updated_at | dateFormatBr() }}
          {{ item.updated_at | timeFormatBr() }}</span
        >
      </template>
    </modalLateral>
  </div>
</template>
<script>
import logo from "@/assets/images/logos/legiio.png";
import Crud from "@/components/Crud.vue";
import ModalLateral from "@/components/ModalLateral.vue";

export default {
  name: "gestao-papel",
  inject: ["$validator"],
  components: { Crud, ModalLateral },
  metaInfo() {
    return {
      title: "Gestão de papéis",
    };
  },
  data() {
    return {
      image: logo,
      searchTextField: "",
      dialog: false,
      loadingControl: {
        dataTable: false,
      },
      item: [],
      dialogDelete: false,
      dialog: false,
      headers: [
        { text: "Nome", value: "name", sortable: true },
        {
          text: "Local",
          value: "guard_name",
          sortable: false,
        },
        {
          text: "Total de permissões",
          value: "permissoes",
          sortable: true,
        },
        {
          text: "Cadastro",
          value: "created_at",
          sortable: true,
        },
        {
          text: "Atualização",
          value: "updated_at",
          sortable: true,
        },
        { text: "Ações", value: "acao", sortable: false, align: "center" },
      ],
      headersModal: [
        {
          text: "Nome",
          value: "name",
        },
        {
          text: "Local",
          value: "guard_name",
        },
        {
          text: "Permissões",
          value: "permissoes",
        },
        {
          text: "Data de criação",
          value: "created_at",
        },
        {
          text: "Última alteração",
          value: "updated_at",
        },
      ],
      locais: [
        { text: "API", value: "api" },
        { text: "WEB", value: "web" },
      ],
      listaDataTable: [],
      objetoSalvar: [],
      lista: [],
      nome: "",
      guard_name: "",
      modalLateral: {
        show: false,
        item: [],
        title: "",
      },
      buttonAction: false,
      objetoEdicao: {
        name: "",
        guard_name: "",
        permissoes: [],
      },
      indexEdicao: false,
      posicaoEdicao: "",
    };
  },
  methods: {
    editar(item) {
      this.objetoEdicao = {
        name: "",
        guard_name: "",
        permissoes: "",
      };
      this.indexEdicao = true;
      this.posicaoEdicao = this.listaPapeis.indexOf(item);
      this.objetoEdicao = Object.assign({}, item);
      this.dialog = true;
    },
    resetFields() {},
    fechar() {
      this.dialog = false;
      this.objetoEdicao = {
        name: "",
        guard_name: "",
        permissoes: [],
      };
    },
    salvar() {
      this.$validator.validate("roles.*").then((result) => {
        if (result) {
          this.loadingControl.dataTable = true;

          let url =
            this.indexEdicao === false
              ? "/papel"
              : "/papel/" + this.objetoEdicao.id;
          let method = this.indexEdicao === false ? "POST" : "PUT";
          let text =
            this.indexEdicao === false
              ? "Papel cadastrado."
              : "Papel atualizado.";
          let erro =
            this.indexEdicao === false
              ? "Erro ao salvar papel."
              : "Erro ao atualizar papel.";
          let objeto = this.objetoEdicao;
          objeto._method = method;
          objeto.url = url;

          this.$store
            .dispatch("configuracoes/salvarPapel", { objeto })
            .then((response) => {
              this.indexEdicao === false
                ? this.listaPapeis.push(response)
                : Object.assign(this.listaPapeis[this.posicaoEdicao], objeto);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: text,
              });
            })
            .catch((error) => {
              console.log(error);
              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "error",
                timeout: 3000,
                text: erro,
              });
            })
            .finally(() => {
              this.loadingControl.dataTable = false;
              this.indexEdicao = false;
            });
        } else {
          this.$store.dispatch("snackBarModule/openSnackBar", {
            color: "error",
            timeout: 3000,
            text: "Preencha todo formulário antes de salvar!",
          });
        }

        this.fechar();
      });
    },
    remover(item, openModal = false) {
      if (openModal) {
        this.item = item;
        this.dialogDelete = true;
      } else {
        this.loadingControl.dataTable = true;
        const indexItem = this.listaPapeis.findIndex(
          (el) => el.id == this.item.id
        );

        this.$store
          .dispatch("configuracoes/removerPapel", this.item.id)
          .then((response) => {
            if (response.data === true) {
              this.listaPapeis.splice(indexItem, 1);

              this.$store.dispatch("snackBarModule/openSnackBar", {
                color: "success",
                timeout: 3000,
                text: "Registro removido.",
              });
              this.item = {};
            }
          })
          .catch(() => {
            store.dispatch("snackBarModule/openSnackBar", {
              color: "error",
              timeout: 3000,
              text: "Não foi possivel remover o registro.",
            });
          })
          .finally(() => {
            this.loadingControl.dataTable = false;
          });

        this.dialogDelete = false;
      }
    },
    toggleModalLateral(item) {
      this.modalLateral.title = this.modalLateral.show ? "" : item.nome;
      this.modalLateral.item = this.modalLateral.show ? [] : item;
      this.modalLateral.show = this.modalLateral.show ? false : true;
    },
    iconSelect(tipo, item) {
      if (this.listaPermissoes && this.objetoEdicao.permissions) {
        if (this.listaPermissoes.length == this.objetoEdicao.permissions.length)
          return "mdi-close-box";
        if (this.objetoEdicao.permissions.length > 0) return "mdi-minus-box";
      }
      return "mdi-checkbox-blank-outline";
    },
    toggleSelect(tipo) {
      this.listaPermissoes.length == this.objetoEdicao.permissions.length
        ? (this.objetoEdicao.permissions = [])
        : (this.objetoEdicao.permissions = this.listaPermissoes.map(
            (t) => t.id
          ));
    },
  },
  computed: {
    computedHeaders() {
      return this.headers.filter(
        (h) => !h.hide || !this.$vuetify.breakpoint[h.hide]
      );
    },
    formTitle() {
      return this.indexEdicao === false ? "Cadatrar papel" : "Editar papel";
    },
    listaPapeis: {
      get() {
        return this.$store.getters["configuracoes/getPapeis"];
      },
      set(value) {
        this.$store.dispatch("configuracoes/setPapeis", value);
      },
    },
    loading: {
      get() {
        return this.$store.getters["configuracoes/getLoadingPermissoes"];
      },
      set(value) {
        this.$store.dispatch("configuracoes/setLoadingPermissoes", value);
      },
    },
    listaPermissoes: {
      get() {
        return this.$store.getters["configuracoes/getPermissoes"];
      },
      set(value) {
        this.$store.dispatch("configuracoes/setPermissoes", value);
      },
    },
  },
  created() {
    this.$store.dispatch("configuracoes/buscarPapeis");
    this.$store.dispatch("configuracoes/buscarPermissoes");
    //this.$store.dispatch("configuracoes/carregarMenus");
  },
};
</script>
<style lang="scss"></style>
